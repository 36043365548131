import styles from "./radioInput.module.scss";
import { memo } from "react";
import RadioGroup from '@mui/material/RadioGroup';
import Radio,  { IRadio } from "@source/components/UI/buttons/radio/radio";

export default memo((props: RadioInputProps) => {
  const {children, name, disabled,  onChange, radios, value, className, innerClassName, label, labelPlace='end', required, error, touched, id} = props;

  return(
    <div className={`${styles.radioInput} ${className}`}>
      {label
        ? <div className={styles.radioInput_label}>
            <div className={styles.radioInput_label_text}>{label}{required ? <span>*</span> : ''}</div>

            {touched && error
              ? <div className={styles.radioInput_error}>{error}</div>
              : <></>}
          </div>
        : <></>}

      <RadioGroup id={id} className={innerClassName} style={{alignItems: labelPlace === "start" ? "flex-end" : "flex-start"}} name={name} onChange={onChange} value={value}>
        {radios.map((radio, i) => (
          <>
            <Radio {...radio}
                   labelPlace={labelPlace}
                   key={i}
                   error={touched && !!error}
                   className={`${styles.radioInput_radio} 
                          ${radio.className}`}
                   disabled={disabled}
            />
            {value === radio.value ? children : <></>}
          </>
        ))}
      </RadioGroup>
    </div>
  )
})

interface RadioInputProps {
  /** RadioInput name */
  name: string,

  /** Selected radio value */
  value: string | number | boolean,

  /** Radios items */
  radios: Array<IRadio>,

  /** Change handler */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,

  children?: any;

  /** RadioInput label */
  label?: string

  /** className for container wrapping RadioInput component */
  className?: string,
  innerClassName?: string,
  /** Radio items label placement
   * @default end
   */
  labelPlace?: 'end' | 'start' | 'top' | 'bottom',

  /** Required status
   * @default false
   */
  required?: boolean,

  /** RadioInput error message */
  error?: string,

  /** RadioInput touced */
  touched?: boolean,

  /** RadioInput id */
  id?: string,

  disabled?: boolean;
}